import React, { useState } from "react";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    "https://firebasestorage.googleapis.com/v0/b/ope-website-392721.appspot.com/o/headers%2Fope.png?alt=media&token=2913d77c-96ba-437e-a305-66d7e00bf644"
  ];
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 3 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 3 ? 0 : (prev) => prev + 1);
  };
  return (
    <div className="w-full h-auto overflow-x-hidden">
      <div className="h-[650px] w-screen relative">
        <div
          style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
          className="w-[400vw] h-full flex transition-transform duration-1000"
        >
          <img
            className=" object-contain p-0"
            src={data[0]}
            alt="ImageOne"
            loading="priority"
          />
         s
        </div>
          {/* <div className="absolute w-fit left-0 right-0 mx-auto flex gap-8 bottom-52">
            <div
              onClick={prevSlide}
              className="w-14 h-12 border-[1px] border-gray-700 flex items-center justify-center hover:cursor-pointer hover:bg-gray-700 hover:text-white active:bg-gray-900 duration-300"
            >
              <HiArrowLeft />
            </div>
            <div
              onClick={nextSlide}
              className="w-14 h-12 border-[1px] border-gray-700 flex items-center justify-center hover:cursor-pointer hover:bg-gray-700 hover:text-white active:bg-gray-900 duration-300"
            >
              <HiArrowRight />
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default Banner;
